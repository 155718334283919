import React, { useEffect } from 'react';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';

import { ErrorBoundary } from './Sentry';
import { LAUNCH_DARKLY_CLIENT_SIDE_ID } from './utils/environment.ts';
import { getLDUser, updateLaunchDarklyUser } from './utils/launchDarkly';
import Main from './Main';

const localStorageAgentName = localStorage.getItem('agentname');

function App() {
  const ldClient = useLDClient();

  useEffect(() => {
    document.addEventListener(
      'agentNameUpdated',
      (event) => updateLaunchDarklyUser(event, ldClient),
      false,
    );
  }, [ldClient]);

  return (
    <React.StrictMode>
      <ErrorBoundary>
        <Main />
      </ErrorBoundary>
    </React.StrictMode>
  );
}

/* It is a common pattern to use the async provider here but as we are using
 * some legacy JQuery, rendering the App async would mean that the JQuery code
 * runs ahead of the app rendering the first time and thus will not have an
 * effect. Therefore we are setting up the provider synchronously
 */
const AppWithFeatureFlags = withLDProvider({
  clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_ID,
  user: getLDUser(localStorageAgentName),
})(App);

export default AppWithFeatureFlags;
