import React from 'react';

const InformationPanel = () => (
  <div id="content-wrapper">
    <h5 id="templateHeader"> </h5>
    <div style={{ display: 'flex' }}>
      <p id="templateContent" />
    </div>
    <div id="defaultContent" className="row">
      <div className="col-12" style={{ textAlign: 'center' }}>
        <img src="images/Miles_Green.png" alt="Miles_Green" width={80} height={85} />
        {' '}
        <br />
        <br />
        <p className="defaultText">Please click on something</p>
      </div>
    </div>
    <div id="wikiTable" style={{ display: 'none' }}>
      <div id="wikiDataTableDiv">
        <table width="100%" id="wikiDataTable" className="tdisplay cell-border compact" />
      </div>
      <div id="wikiEnquiryTableDiv">
        <table width="100%" id="wikiEnquiryTable" className="tdisplay cell-border compact" />
      </div>
    </div>
    <div id="footerBtns">
      <button id="editBtn" type="button" aria-hidden="true" data-toggle="modal" data-target="#editModal" style={{ display: 'none' }}>Edit</button>
      <button id="editWikiBtn" type="button" onClick={() => (window as any).editWikiBtnclick()} style={{ display: 'none' }}>Edit</button>
      <button id="editSRBtn" type="button" aria-hidden="true" data-toggle="modal" data-target="#editWikiSRModal" style={{ display: 'none' }}>Edit</button>
      <button id="editEnquiryBtn" type="button" aria-hidden="true" data-toggle="modal" data-target="#editWikiEnquiryModal" style={{ display: 'none' }}>Edit</button>
    </div>
  </div>
);

export default InformationPanel;
