import React, { useState, useEffect, useRef } from 'react';
import './DropdownBox.css';
import { reactLocalStorageService } from '../../../utils/localStorageService';

// @ts-ignore
const DropdownBox = ({ selectedCallToWrap, setSelectedCallToWrap }) => {
  const [dropdownToggled, setDropdownToggled] = useState<boolean>(false);
  const dropdownRef = useRef(null);
  const engineerNameDisplay = selectedCallToWrap.customerName === undefined
    ? 'Select Engineer'
    : selectedCallToWrap.customerName.value;

  useEffect(() => {
    function dropdownHandler(e:any) {
      if (dropdownRef.current) {
        // @ts-ignore
        if (!dropdownRef.current.contains(e.target)) {
          setDropdownToggled(false);
        }
      }
    }
    document.addEventListener('click', dropdownHandler);

    return (() => {
      document.removeEventListener('click', dropdownHandler);
    });
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button className="toggle" type="button" onClick={() => { setDropdownToggled(!dropdownToggled); }}>
        <span>{engineerNameDisplay}</span>
      </button>
      <div className={`options ${dropdownToggled ? 'show' : null}`}>
        {reactLocalStorageService.getCallsToWrap().map((option: any) => (
          <button
            key={option.contactId}
            type="button"
            onClick={() => {
              setSelectedCallToWrap({ ...option });
              setDropdownToggled(false);
            }}
          >
            {
              `${option.customerName.value} - ${option.chatReason.value}`
            }
          </button>
        ))}
      </div>
    </div>
  );
};

export default DropdownBox;
