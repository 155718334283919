import React from 'react';

const NewWikiEnquiryRowModal = () => (
  <div id="addNewWikiEnquiryrow" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog editDialog">
      <div className="modal-content editContentModal">
        <div style={{ padding: 10 }}>
          <div className="modal-header editpopupHeader" style={{ padding: 5 }}>
            <h5 id="addNewEnquiryTitle" className="col-12 modal-title popupTitle" style={{ textAlign: 'left' }}> </h5>
          </div>
          <div className="modal-body addWikiPopupBody">
            <div className="row addWikiRow">
              <div id="addwikiEnquiryError" style={{ display: 'none' }} />
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-3">
                <span className="addWikiHeaders">Description </span>
              </div>
              <div className="col-sm-9">
                <textarea id="addEnqiryDescription" className="addWikiTextarea" placeholder="Required" rows={3} cols={50} maxLength={300} defaultValue="" />
              </div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-3">
                <span className="addWikiHeaders">Information </span>
              </div>
              <div className="col-sm-9">
                <textarea id="addEnquiryInformation" className="addWikiTextarea" placeholder="Required" rows={3} cols={50} maxLength={300} defaultValue="" />
              </div>
            </div>
          </div>
          <div className="modal-footer editpopupFooter">
            <button type="button" className="btn cancelSection" onClick={() => (window as any).addNewWikiEnquiryrowCancel()}>Cancel</button>
            <button type="button" className="btn DoneBtn" onClick={() => (window as any).insertNewWikiEnquiryRow()}>Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NewWikiEnquiryRowModal;
