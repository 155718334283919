import * as dotenv from 'dotenv';

dotenv.config();

const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_ORGANIZATION,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_TRACING_SAMPLE_RATE,
  REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
} = process.env;

function envAssert(envVar: any, name: string): void {
  if (!envVar) throw new Error(`Environment Variable ${name} is missing`);
}

envAssert(REACT_APP_ENVIRONMENT, 'REACT_APP_ENVIRONMENT');
envAssert(REACT_APP_ORGANIZATION, 'REACT_APP_ORGANIZATION');
envAssert(REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID, 'REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID');

const isSentryEnabled = REACT_APP_ENVIRONMENT === 'uat'
                        || REACT_APP_ENVIRONMENT === 'prd';

if (isSentryEnabled) {
  envAssert(REACT_APP_SENTRY_DSN, 'REACT_APP_SENTRY_DSN');
  envAssert(
    REACT_APP_SENTRY_TRACING_SAMPLE_RATE,
    'REACT_APP_SENTRY_TRACING_SAMPLE_RATE',
  );
}

export {
  REACT_APP_ENVIRONMENT as ENVIRONMENT,
  REACT_APP_ORGANIZATION as ORGANIZATION,
  REACT_APP_SENTRY_DSN as SENTRY_DSN,
  REACT_APP_SENTRY_TRACING_SAMPLE_RATE as SENTRY_TRACING_SAMPLE_RATE,
  REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID as LAUNCH_DARKLY_CLIENT_SIDE_ID,
};
