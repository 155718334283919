import React from 'react';
import ReactDOM from 'react-dom';
import AppWithFeatureFlags from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <AppWithFeatureFlags />,
  document.getElementById('root'),
);

reportWebVitals();
