import React from 'react';

const Navigation = () => (
  <div id="right-nav" style={{ flex: 'auto', marginRight: 20 }}>
    <div>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a className="nav-link active" id="home-tab" data-toggle="tab" href="#templatetab" role="tab" aria-controls="template" aria-selected="true">Template</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" id="profile-tab" data-toggle="tab" href="#wikitab" role="tab" aria-controls="profile" aria-selected="false">Field Support Wiki</a>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="templatetab" role="tabpanel" aria-labelledby="template-tab">
          <nav id="sidebar">
            <div className="p-4 pt-5 templates tab-pane" id="templates" />
            <div className="templateBtns">
              <button type="button" id="newTemplateSection" className="btn" onClick={() => (window as any).openNewTemplateSection()} style={{ display: 'none' }}>Add Section</button>
              <button type="button" id="deleteSection" className="btn" aria-hidden="true" data-toggle="modal" data-target="#deleteTemplateConfirm" style={{ display: 'none' }}>Delete Section</button>
            </div>
          </nav>
        </div>
        <div className="tab-pane fade" id="wikitab" role="tabpanel" aria-labelledby="wiki-tab">
          <nav id="sidebar" style={{ wordBreak: 'break-word' }}>
            <div style={{ width: '95%', padding: '5% 0 5% 5%' }}>
              <select id="wikiData" aria-label="type to search" placeholder="Type here to search"> </select>
            </div>
            <div className="wikiButtons">
              <button type="button" id="newWikiSR" className="btn wikiBtns" onClick={() => (window as any).displayaddNewWiki()}>Add Wiki</button>
              <button type="button" id="deleteWiki" className="btn wikiBtns" aria-hidden="true" data-toggle="modal" data-target="#deleteWikiConfirmation" style={{ display: 'none' }}>Delete Wiki</button>
            </div>
          </nav>
        </div>
      </div>
      {/* Log Enquiry Contact Button start */}
      <div className="logEnquiryContactBtnDiv">
        <button
          id="logEnquiryContactBtn"
          type="button"
          className="logEnquiryContactBtn"
          aria-hidden="true"
          onClick={() => (window as any).selectEngineerToWrap()}
          data-toggle="modal"
          data-target
        >
          Log Enquiry Contact
        </button>
      </div>
      {/* Log Enquiry Contact Button end */}
    </div>
  </div>
);

export default Navigation;
