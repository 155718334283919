import React from 'react';
import DropdownBox from '../../../ComponentLibrary/DropdownBox/DropdownBox';
// @ts-ignore
function SelectEngineerWrapModal({ selectedCallToWrap, setSelectedCallToWrap }) {
  return (
    <div id="selectEngineerWrapModal" className="modal" style={{ display: 'none' }} role="dialog" data-backdrop="static" data-keyboard="false">
      <div className="modal-dialog editDialog">
        <div className="modal-content editContentModal">
          <div style={{ padding: 10 }}>
            <div className="modal-body confirmpopupBody">
              <div className="editMilesIcon">
                <img src="images/Miles_Green.png" alt="Miles_Green" width={60} height={60} />
              </div>
              <span className="editmodalText">
                Please select an engineer to wrap
              </span>
              <DropdownBox
                selectedCallToWrap={selectedCallToWrap}
                setSelectedCallToWrap={setSelectedCallToWrap}
              />
              <div className="editFooterBtn">
                <button type="button" className="btn cancelBtn" onClick={() => (window as any).hideModal('selectEngineerWrapModal')}>Cancel</button>
                <button type="button" className="btn confirmBtn" onClick={() => (window as any).getHierarchyDataLogEnquiryContact()}>
                  Wrap Contact
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectEngineerWrapModal;
