import React from 'react';

const NewTemplateModal = () => (
  <div id="addNewSection" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog editDialog">
      <div className="modal-content editContentModal">
        <div style={{ padding: 10 }}>
          <div className="modal-body editPopupBody">
            <div id="titleError" style={{ display: 'none' }}>Oops! Please ensure all fields are completed</div>
            <div>
              <textarea id="insertTemplateTitle" className="editTextarea" placeholder="Insert title here…" defaultValue="" />
              <textarea id="insertTemplate" className="editTextarea" placeholder="Type a template here" maxLength={300} defaultValue="" />
            </div>
          </div>
          <div className="modal-footer editpopupFooter">
            <button type="button" className="btn cancelSection" data-dismiss="modal">Cancel</button>
            <button type="button" className="btn DoneBtn" onClick={() => (window as any).insertNewSection()}>Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NewTemplateModal;
