import React from 'react';
import { ORGANIZATION } from '../../../../utils/environment';

const EditWikiSRRowDetailsModal = () => (
  <div id="editWikiSRrowDetails" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog editDialog">
      <div className="modal-content editContentModal">
        <div style={{ padding: 10 }}>
          <div className="modal-header editpopupHeader" style={{ padding: 5, display: 'block' }}>
            <h5 id="editWikiSRrowDetailsTitle" className="col-12 modal-title popupTitle" style={{ textAlign: 'left' }}> </h5>
            <div id="editwikiSRErrorDetails" style={{ display: 'none' }} />
          </div>
          <div className="modal-body addWikiPopupBody">
            <div className="row addWikiRow">
              <div className="col-sm-12"><input type="text" id="editWikiSRID" className="addWikiTextarea" hidden /></div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">SR No </span></div>
              <div className="col-sm-8"><input type="text" id="editWikiSRNum" className="addWikiTextarea" placeholder="Required" disabled /></div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">Device Type </span></div>
              <div className="col-sm-8">
                <input type="text" className="addWikiSRSelect" id="editwikiSRDeviceType" placeholder="Required" disabled />
              </div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">Payment </span></div>
              <div className="col-sm-8">
                <input type="text" className="addWikiSRSelect" id="editwikiSRPayment" placeholder="Required" disabled />
              </div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4">
                <span className="addWikiHeaders">SR Name </span>
              </div>
              <div className="col-sm-8">
                <input type="text" id="editWikiSRName" className="addWikiTextarea" placeholder="Required" disabled />
              </div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4">
                <span className="addWikiHeaders">
                  {ORGANIZATION === 'SSE' ? 'DUIS No.' : 'MMC Response'}
                </span>
              </div>
              <div className="col-sm-8">
                <select className="addWikiSRSelect" id="editwikiSRSyncAsync">
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4">
                <span className="addWikiHeaders">Timeout (Minutes) </span>
              </div>
              <div className="col-sm-8">
                <input
                  type="number"
                  id="editwikiSRTimeout"
                  className="addWikiTextarea"
                  placeholder="Required"
                  /* eslint-disable */
                  onKeyPress={(event) => !(event.charCode === 43 || event.charCode === 45 || event.charCode === 46 || event.charCode === 101 || event.charCode === 69)}
                  // @ts-ignore
                  onKeyDown={(event) => {if (this.value.length === 3 && event.keyCode !== 8) return false;}}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer editpopupFooter">
            <button type="button" className="btn cancelSection" data-dismiss="modal">Cancel</button>
            <button type="button" className="btn DoneBtn" onClick={() => (window as any).updateNewWikiSRRow()}>Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EditWikiSRRowDetailsModal;
