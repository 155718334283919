import React from 'react';

const EditWikiSRModal = () => (
  <div id="editWikiSRModal" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog editDialog">
      <div className="modal-content editContentModal">
        <div style={{ padding: 10 }}>
          <div className="modal-header editpopupHeader" style={{ padding: 5 }}>
            <h5 id="editSRTitle" className="col-12 modal-title popupTitle" style={{ textAlign: 'left' }}> </h5>
          </div>
          <div className="modal-body editPopupBody">
            <div className="row" style={{ width: '95%' }}>
              <span id="editSRErrors" style={{ display: 'none' }} />
            </div>
            <div id="editWikiSR">
              <table width="100%" id="editWikiDataTable" className="tdisplay cell-border compact" />
            </div>
          </div>
          <div className="modal-footer editpopupFooter">
            <button type="button" id="addNewWikiSR" className="btn" aria-hidden="true" data-toggle="modal" data-target="#addNewWikiSRrow">
              Add
            </button>
            <button type="button" className="btn DoneBtn" data-dismiss="modal" style={{ marginRight: '-5px' }}>Done</button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EditWikiSRModal;
