import React from 'react';
// @ts-ignore
function LogEnquiryFormTab({ selectedCallToWrap, setSelectedCallToWrap }) {
  // @ts-ignore
  const engineerNameDisplay = selectedCallToWrap?.customerName?.value ?? '';

  const handleEngineerNameInputChange = (event: any) => {
    setSelectedCallToWrap({
      ...selectedCallToWrap,
      customerName: { value: event.target.value },
    });
  };

  return (
    <div className="templates">
      <div>
        <label htmlFor="customerName" className="marginZero labelStyle enquiryLabel">
          Engineer Name
          <input
            type="text"
            className="form-control form-control-sm hierarchySelect"
            id="customerName"
            value={engineerNameDisplay}
            onChange={handleEngineerNameInputChange}
            maxLength={50}
          />
        </label>
      </div>
      <div>
        <label htmlFor="Hierarchy1" className="marginZero labelStyle enquiryLabel">
          Chat reason: level 1
          <select className="form-control form-control-sm hierarchySelect" aria-label="Reason" id="Hierarchy1" />
        </label>
      </div>
      <div>
        <label htmlFor="Hierarchy2" className="marginZero labelStyle enquiryLabel">
          Chat reason: level 2
          <select className="form-control form-control-sm hierarchySelect" id="Hierarchy2">
            <option value="">Select Reason</option>
          </select>
        </label>
      </div>
      <div>
        <label htmlFor="Hierarchy3" className="marginZero labelStyle enquiryLabel">
          Chat reason: level 3
          <select className="form-control form-control-sm hierarchySelect" id="Hierarchy3">
            <option value="">Select Reason</option>
          </select>
        </label>
      </div>
    </div>
  );
}

export default LogEnquiryFormTab;
