import React from 'react';
import LogEnquiryFormTab from '../LogEnquiryFormTab/LogEnquiryFormTab';

// @ts-ignore
function LogEnquiryModal({ selectedCallToWrap, setSelectedCallToWrap }) {
  return (
    <div id="logEnquiry-content-wrapper" style={{ display: 'none' }}>
      <div id="getHierarchyDataLogEnquiryContact">
        <h5 id="templateHeader">Log Enquiry Contact</h5>
      </div>
      <div style={{ display: 'flex' }}>
        <p id="templateContent">
          Please copy the Engineer name and select the reason for the contact from the drop down
          menus.
        </p>
      </div>
      <div id="logEnquiryError" style={{ display: 'none' }} />
      <LogEnquiryFormTab
        selectedCallToWrap={selectedCallToWrap}
        setSelectedCallToWrap={setSelectedCallToWrap}
      />
      <div className="templateBtnsRight">
        <button
          className="btn logEnquiryContactbtnCancel"
          type="submit"
          onClick={() => (window as any).logEnquiryContactCancel()}
        >
          Cancel
        </button>
        <button
          className="btn logEnquiryContactbtnSubmit"
          type="submit"
          onClick={() => (window as any).addLogEnquiryContact()}
        >
          Submit
        </button>
      </div>
      <div id="editEnquiryfooterBtns">
        <button
          id="editEnquiryFormBtn"
          className="editEnquiryFormBtn"
          type="submit"
          onClick={() => (window as any).editEnquiryFormModal()}
          style={{ display: 'none' }}
        >
          Edit
        </button>
      </div>
    </div>
  );
}

export default LogEnquiryModal;
