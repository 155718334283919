import React from 'react';
import { ORGANIZATION } from '../../../../utils/environment';

const NewWikiSRRowModal = () => (
  <div id="addNewWikiSRrow" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog editDialog">
      <div className="modal-content editContentModal">
        <div style={{ padding: 10 }}>
          <div className="modal-header editpopupHeader" style={{ padding: 5, display: 'block' }}>
            <h5 id="addWikiSrRowTitle" className="col-12 modal-title popupTitle" style={{ textAlign: 'left' }}> </h5>
            <div id="addwikiSRrowError" style={{ display: 'none' }} />
          </div>
          <div className="modal-body addWikiPopupBody">
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">SR No </span></div>
              <div className="col-sm-8"><input type="text" id="addSRNumber" className="addWikiTextarea" placeholder="Required" /></div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">Device Type </span></div>
              <div className="col-sm-8">
                <input type="text" className="addWikiSRSelect" id="addwikiSRDeviceType" placeholder="Required" disabled />
              </div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">Payment </span></div>
              <div className="col-sm-8">
                <input type="text" className="addWikiSRSelect" id="addwikiSRPayment" placeholder="Required" disabled />
              </div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">SR Name </span></div>
              <div className="col-sm-8"><input type="text" id="addWikiSRName" className="addWikiTextarea" placeholder="Required" /></div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4">
                <span className="addWikiHeaders">
                  { ORGANIZATION === 'SSE' ? 'DUIS No.' : 'MMC Response'}
                </span>
              </div>
              <div className="col-sm-8">
                <select className="addWikiSRSelect" id="addwikiSRSyncAsync">
                  <option selected hidden>Please select an option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4">
                <span className="addWikiHeaders">Timeout (Minutes) </span>
              </div>
              <div className="col-sm-8">
                <input
                  type="number"
                  id="addwikiSRTimeout"
                  className="addWikiTextarea"
                  placeholder="Required"
                  /* eslint-disable */
                  onKeyPress={(event) => !(event.charCode === 43 || event.charCode === 45 || event.charCode === 46 || event.charCode === 101 || event.charCode === 69)}
                  // @ts-ignore
                  onKeyDown={(event) => { if (this.value.length === 3 && event.keyCode !== 8) return false; }}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer editpopupFooter">
            <button type="button" className="btn cancelSection" onClick={() => (window as any).deleteNewWikiSRRow()}>Cancel</button>
            <button type="button" className="btn DoneBtn" onClick={() => (window as any).insertNewWikiSRRow()}>Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NewWikiSRRowModal;
