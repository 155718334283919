import React from 'react';

const MaxTemplateModal = () => (
  <div id="maxTemplate" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog editDialog">
      <div className="modal-content editContentModal">
        <div style={{ padding: 10 }}>
          <div className="modal-body confirmpopupBody">
            <div className="editMilesIcon">
              <img src="images/Miles_Green.png" alt="Miles_Green" width={60} height={60} />
            </div>
            <span className="editmodalText">Sorry, you have reached the maximum number of templates</span>
            <div className="editFooterBtn">
              <button type="button" className="btn DoneBtn" data-dismiss="modal">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MaxTemplateModal;
