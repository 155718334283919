import React from 'react';

const EditNewWikiEnquiryRowModal = () => (
  <div id="editNewWikiEnquiryrow" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog editDialog">
      <div className="modal-content editContentModal">
        <div style={{ padding: 10 }}>
          <div className="modal-header editpopupHeader" style={{ padding: 5 }}>
            <h5 id="editNewEnquiryTitle" className="col-12 modal-title popupTitle" style={{ textAlign: 'left' }}> </h5>
          </div>
          <div className="modal-body addWikiPopupBody">
            <div className="row addWikiRow">
              <div id="editwikiEnquiryError" style={{ display: 'none' }} />
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-12">
                <input type="text" id="editEnqiryId" hidden />
              </div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-3">
                <span className="addWikiHeaders">Description </span>
              </div>
              <div className="col-sm-9">
                <textarea id="editEnqiryDescription" className="addWikiTextarea" placeholder="Required" rows={3} cols={50} maxLength={300} defaultValue="" />
              </div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-3">
                <span className="addWikiHeaders">Information </span>
              </div>
              <div className="col-sm-9">
                <textarea id="editEnquiryInformation" className="addWikiTextarea" placeholder="Required" rows={3} cols={50} maxLength={300} defaultValue="" />
              </div>
            </div>
          </div>
          <div className="modal-footer editpopupFooter">
            <button type="button" className="btn cancelSection" data-dismiss="modal">Cancel</button>
            <button type="button" className="btn DoneBtn" onClick={() => (window as any).editNewWikiEnquiryRow()}>Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EditNewWikiEnquiryRowModal;
