/* eslint-disable react/button-has-type */
import React, { ReactElement } from 'react';

import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';

import {
  ENVIRONMENT,
  SENTRY_DSN,
  SENTRY_TRACING_SAMPLE_RATE,
} from './utils/environment';

const isSentryEnabled = ENVIRONMENT === 'prd' || ENVIRONMENT === 'uat';

const styles = {
  container: {
    maxWidth: 480,
    fontSize: 18,
    textAlign: 'center' as const,
    margin: 'auto',
  },
};

function FallbackComponent() {
  // const classes = useStyles();
  const reload = () => window.location.reload();
  return (
    <div className="container" style={styles.container}>
      <p>
        {`
        There's a technical problem that we're fixing, please try to reload the app. If this doesn't work, please try again later.
        `}
      </p>
      <button onClick={reload}>
        Reload App
      </button>
    </div>
  );
}

function ErrorBoundary({ children }: { children: ReactElement }) {
  return (
    <Sentry.ErrorBoundary fallback={<FallbackComponent />} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  );
}

function InitializeSentry() {
  if (isSentryEnabled) {
    Sentry.init({
      environment: ENVIRONMENT,
      dsn: `${SENTRY_DSN}`,
      release: `miles-site-support@${process.env.npm_package_version}`,
      integrations: [
        new Integrations.BrowserTracing(),
        new CaptureConsole({
          levels: ['debug', 'error', 'warning'],
        })],
      tracesSampleRate: Number(SENTRY_TRACING_SAMPLE_RATE),
      normalizeDepth: 10,
      maxBreadcrumbs: 20,
    });
  }
}

export {
  ErrorBoundary,
  InitializeSentry,
};
