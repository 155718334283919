import { v5 as uuidv5 } from 'uuid';

function getLDUser(agentName: string | undefined) {
  const uuidNamespace = '9f887857-15ba-4412-aba5-fa78bdd06da9';
  const userKey = agentName ? uuidv5(agentName, uuidNamespace) : 'unknown';
  const isAnonymous = userKey === 'unknown';

  return {
    key: userKey,
    anonymous: isAnonymous,
  };
}

async function updateLaunchDarklyUser(event: any, ldClient: any) {
  const { agentName } = event;
  const uKey = getLDUser(agentName);

  if (ldClient && ldClient.identify) {
    try {
      await ldClient.waitForInitialization();
      await ldClient.identify(uKey);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(
        'Tried to update the LaunchDarkly user. From this we '
          + `got the error: ${err}. User will not be updated.`,
      );
    }
  }
}

export {
  getLDUser,
  updateLaunchDarklyUser,
};
