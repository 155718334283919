import React from 'react';

const NewWikiModal = () => (
  <div id="addNewWiki" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog editDialog">
      <div className="modal-content editContentModal">
        <div>
          <div className="modal-header editpopupHeader">
            <div id="addwikiSRError" style={{ display: 'none' }} />
          </div>
          <div className="modal-body addWikiPopupBody">
            <div className="row addWikiRow">
              <div className="col-sm-12">
                <select className="addWikiSelect" id="addwikiParentList">
                  {/* tech debt - the 'selected' prop causes console error log
                   but is coupled with JQuery logic so it can't be easily removed. */}
                  <option value="" selected hidden>Please select an option</option>
                </select>
              </div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">SR/Error</span></div>
              <div className="col-sm-8"><textarea id="addSRError" className="addWikiTextarea" placeholder="Required" rows={1} cols={50} defaultValue="" /></div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">Error message (SR)</span></div>
              <div className="col-sm-8"><textarea id="addSRErrorSRMsg" className="addWikiTextarea" placeholder="Optional" rows={1} cols={50} defaultValue="" /></div>
            </div>
            <hr style={{ width: '96%', marginLeft: 10 }} />
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">Slide Header</span></div>
              <div className="col-sm-8"><textarea id="addSlideHeader" className="addWikiTextarea" placeholder="Required" rows={1} cols={50} maxLength={80} defaultValue="" /></div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">Parent/Problem/INC</span></div>
              <div className="col-sm-8"><textarea id="addIncidentNo" className="addWikiTextarea" placeholder="Required" rows={1} cols={50} defaultValue="" /></div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">Issue Header</span></div>
              <div className="col-sm-8"><textarea id="addSRTitle" className="addWikiTextarea" placeholder="Required" rows={1} cols={50} defaultValue="" /></div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">Error Message</span></div>
              <div className="col-sm-8"><textarea id="addSRErrorMsg" className="addWikiTextarea" placeholder="Required" rows={1} cols={50} defaultValue="" /></div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">Issue/Root cause</span></div>
              <div className="col-sm-8"><textarea id="addIssueRootCause" className="addWikiTextarea" placeholder="Required" rows={3} cols={50} defaultValue="" /></div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">How to identify/symptoms</span></div>
              <div className="col-sm-8"><textarea id="addSymptoms" className="addWikiTextarea" placeholder="Required" rows={3} cols={50} defaultValue="" /></div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">Workaround:</span></div>
              <div className="col-sm-8"><textarea id="addSRWorkaround" className="addWikiTextarea" placeholder="Required" rows={3} cols={50} defaultValue="" /></div>
            </div>
            <div className="row addWikiRow">
              <div className="col-sm-4"><span className="addWikiHeaders">Asset</span></div>
              <div className="col-sm-8"><textarea id="addWikiAsset" className="addWikiTextarea" placeholder="Required" rows={1} cols={50} defaultValue="" /></div>
            </div>
          </div>
          <div className="modal-footer editpopupFooter">
            <button type="button" className="btn cancelSection" data-dismiss="modal">Cancel</button>
            <button type="button" className="btn DoneBtn" onClick={() => (window as any).insertNewWiki()}>Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NewWikiModal;
