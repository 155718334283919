import React from 'react';
import { reactLocalStorageService } from '../../../../utils/localStorageService';

// @ts-ignore
const LogEnquiryConfirmModal = ({ selectedCallToWrap, setSelectedCallToWrap }) => (
  <div id="logEnquiryContactConfirmation" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog editDialog">
      <div className="modal-content editContentModal">
        <div style={{ padding: 10 }}>
          <div className="modal-body confirmpopupBody">
            <div className="editMilesIcon">
              <img src="images/Miles_Green.png" alt="Miles_Green" width={60} height={60} />
            </div>
            <span className="editmodalText">Are you sure you want to continue?</span>
            <div className="editFooterBtn">
              <button type="button" className="btn cancelBtn" data-dismiss="modal">Cancel</button>
              <button
                type="button"
                className="btn confirmBtn"
                id="insertLogEnquiryContact"
                onClick={() => (window as any).insertLogEnquiryContact(
                  selectedCallToWrap,
                  setSelectedCallToWrap,
                  reactLocalStorageService.logEnquiryResultCallback.bind(reactLocalStorageService),
                )}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default LogEnquiryConfirmModal;
