import React, {useState} from 'react';
import { InitializeSentry } from './Sentry';

import Navigation from './components/WikiPanels/Navigation/Navigation';
import InformationPanel from './components/WikiPanels/InformationPanel/InformationPanel';
import LogEnquiryModal from './components/Modals/LogEnquiryContact/LogEnquiryModal/LogEnquiryModal';
import NewTemplateModal from './components/Modals/Template/NewTemplateModal/NewTemplateModal';
import NewWikiModal from './components/Modals/Wiki/NewWikiModal/NewWikiModal';
import EditWikiEnquiryModal from './components/Modals/Wiki/EditWikiEnquiryModal/EditWikiEnquiryModal';
import DeleteWikiModal from './components/Modals/Wiki/DeleteWikiModal/DeleteWikiModal';
import DeleteTemplateModal from './components/Modals/Template/DeleteTemplateModal/DeleteTemplateModal';
import EditTemplateModal from './components/Modals/Template/EditTemplateModal/EditTemplateModal';
import EditLogEnquiryModal from './components/Modals/LogEnquiryContact/EditLogEnquiryModal/EditLogEnquiryModal';
import EditWikiSRModal from './components/Modals/Wiki/EditWikiSRModal/EditWikiSRModal';
import EditWikiModal from './components/Modals/Wiki/EditWikiModal/EditWikiModal';
import LogEnquiryConfirmModal from './components/Modals/LogEnquiryContact/LogEnquiryConfirmModal/LogEnquiryConfirmModal';
import NewChatReasonConfirmModal from './components/Modals/LogEnquiryContact/NewChatReasonConfirmModal/NewChatReasonConfirmModal';
import DeleteChatReasonConfirmModal from './components/Modals/LogEnquiryContact/DeleteChatReasonConfirmModal/DeleteChatReasonConfirmModal';
import EditChatReasonConfirmModal from './components/Modals/LogEnquiryContact/EditChatReasonConfirmModal/EditChatReasonConfirmModal';
import NewWikiEnquiryRowModal from './components/Modals/Wiki/NewWikiEnquiryRowModal/NewWikiEnquiryRowModal';
import NewWikiEnquiryRowConfirmModal from './components/Modals/Wiki/NewWikiEnquiryRowConfirmModal/NewWikiEnquiryRowConfirmModal';
import EditNewWikiEnquiryRowModal from './components/Modals/Wiki/EditNewWikiEnquiryRowModal/EditNewWikiEnquiryRowModal';
import EditWikiEnquiryRowConfirmModal from './components/Modals/Wiki/EditWikiEnquiryRowConfirmModal/EditWikiEnquiryRowConfirmModal';
import DeleteWikiEnquiryRowConfirmModal from './components/Modals/Wiki/DeleteWikiEnquiryRowConfirmModal/DeleteWikiEnquiryRowConfirmModal';
import CancelConfirmModal from './components/Modals/CancelConfirmModal/CancelConfirmModal';
import SaveConfirmModal from './components/Modals/Template/SaveConfirmModal/SaveConfirmModal';
import DeleteRowConfirmModal from './components/Modals/Template/DeleteRowConfirmModal/DeleteRowConfirmModal';
import SaveNewTemplateModal from './components/Modals/Template/SaveNewTemplateModal/SaveNewTemplateModal';
import DeleteInsertedRowModal from './components/Modals/Template/DeleteInsertedRowModal/DeleteInsertedRowModal';
import MaxTemplateModal from './components/Modals/Template/MaxTemplateModal/MaxTemplateModal';
import SaveInsertNewWikiModal from './components/Modals/Wiki/SaveInsertNewWikiModal/SaveInsertNewWikiModal';
import UpdateWikiConfirmModal from './components/Modals/Wiki/UpdateWikiConfirmModal/UpdateWikiConfirmModal';
import NewWikiSRRowConfirmModal from './components/Modals/Wiki/NewWikiSRRowConfirmModal/NewWikiSRRowConfirmModal';
import DeleteWikiSRRowConfirmModal from './components/Modals/Wiki/DeleteWikiSRRowConfirmModal/DeleteWikiSRRowConfirmModal';
import EditNewWikiSRRowConfirmModal from './components/Modals/Wiki/EditNewWikiSRRowConfirmModal/EditNewWikiSRRowConfirmModal';
import NewWikiSRRowModal from './components/Modals/Wiki/NewWikiSRRowModal/NewWikiSRRowModal';
import EditWikiSRRowDetailsModal from './components/Modals/Wiki/EditWikiSRRowDetailsModal/EditWikiSRRowDetailsModal';
import AgentUserNameErrorModal from './components/Modals/ErrorModals/AgentUserNameErrorModal';
import SelectEngineerWrapModal from './components/Modals/LogEnquiryContact/SelectEngineerWrapModal/SelectEngineerWrapModal';
InitializeSentry();

const Main = () => {
  const [selectedCallToWrap, setSelectedCallToWrap] = useState({});

  return (
    <div>
      <div id="displayLogo" style={{ background: '#198426' }}>
        <img src="images/overlay9.png" alt="logo" id="startImage" />
      </div>
      <div id="displayContent">
        <nav className="navbar navbar-dark" id="banner">
          <div style={{ display: 'flex' }}>
            <img style={{ marginLeft: 30 }} src="images/RGB_logo_white_A1.png" alt="logo" width={100} height={40} />
            <span className="milesHeader">
              MILES
            </span>
          </div>
        </nav>
        <div style={{ marginBottom: 15 }}>
          <button type="button" className="userIcon" id="loginIcon" aria-hidden="true" onClick={() => window.logInFunc()}>Manager View</button>
          <button type="button" className="userIcon" id="logoutIcon" onClick={() => window.logOutFunc()}>Log out</button>
        </div>
        <div className="d-md-flex align-items-stretch">
          {/* Page Content  */}
          <div id="content" className="p-4 p-md-5 pt-5">
            <div id="chatDiv">
              <div id="containerDiv" />
                  <div id="keyInfoContainer">
                    <span id="mpxnName">
                      -
                    </span>
                    <span id="TextToCopy" className="fa fa-copy mr-2 padding-5" onClick={() => copyToClipboard('#mpxnValue')}></span>
                    <span id="mpxnValue">
                      -
                    </span>
                  </div>
            </div>
            <div id="contentDiv">
              <InformationPanel />
              <LogEnquiryModal
                selectedCallToWrap={selectedCallToWrap}
                setSelectedCallToWrap={setSelectedCallToWrap}
              />
            </div>
          </div>
          <Navigation />
        </div>
      </div>
      <div id="resolvedModal" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog popupModal">
          <div className="modal-content confirmationModal">
            <div style={{ padding: 10 }}>
              <div className="modal-header popupHeader">
                <h5 className="col-12 modal-title popupTitle">
                  Resolved
                </h5>
              </div>
              <div className="modal-body popupBody">
                <span className="modalText">
                  You resolved the issue using this guidance.
                  <br />
                  {' '}
                  Do you wish to submit?
                </span>
              </div>
              <div className="modal-footer popupFooter">
                <button type="button" id="resolvedYes" className="btn confirmBtn" onClick={() => window.resolvedFunc(this.id)}>Yes</button>
                <button type="button" className="btn cancelBtn" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="unresolvedModal" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog popupModal">
          <div className="modal-content confirmationModal">
            <div style={{ padding: 10 }}>
              <div className="modal-header popupHeader">
                <h5 className="col-12 modal-title popupTitle">Unresolved</h5>
              </div>
              <div className="modal-body popupBody">
                <span className="modalText">
                  You were unable to resolve the issue using this guidance.
                  <br />
                  {' '}
                  Do you wish to submit?
                </span>
              </div>
              <div className="modal-footer popupFooter">
                <button type="button" id="unresolvedYes" className="btn confirmBtn" onClick={() => window.resolvedFunc(this.id)}>Yes</button>
                <button type="button" className="btn cancelBtn" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="managerLogin" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog popupModal">
          <div className="modal-content confirmationModal">
            <div style={{ padding: 10 }}>
              <div className="modal-body">
                <div className="milesIcon">
                  <img src="images/Miles_Green.png" alt="Miles_Green" width={60} height={60} />
                </div>
                <div id="loginPanel">
                  <span className="modalText">Manager Login</span>
                  <form name="loginDetails" id="loginDetails" className="login100-form validate-form" onSubmit={() => {return false}}>
                    <fieldset id="emailField">
                      <legend>Email Address</legend>
                      <input type="text" id="emailAddr" name="emailAddr" />
                      <i className="fa fa-exclamation-circle" aria-hidden="true" id="emailrequired" />
                    </fieldset>
                    <fieldset id="passwdField">
                      <legend>Password</legend>
                      <input type="password" id="password" name="password" />
                      <i className="fa fa-eye" id="togglePassword" />
                      <i className="fa fa-exclamation-circle" aria-hidden="true" id="pwdrequired" />
                    </fieldset>
                    <label id="errorMsg" />
                    <div className="container-login100-form-btn loginBtn">
                      <input type="submit" className="login100-form-btn" defaultValue="Log In" id="login" onClick={() => window.initiateLogin(this.id)} />
                      <input type="button" className="cancel-form-btn" defaultValue="Cancel" id="cancel" onClick={() => window.resetForm()} />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditTemplateModal />
      <EditWikiModal />
      <NewWikiModal />
      <EditWikiSRModal />
      <EditWikiEnquiryModal />
      <NewWikiEnquiryRowModal />
      <NewWikiEnquiryRowConfirmModal />
      <EditNewWikiEnquiryRowModal />
      <EditWikiEnquiryRowConfirmModal />
      <DeleteWikiEnquiryRowConfirmModal />
      <CancelConfirmModal />
      <SaveConfirmModal />
      <DeleteRowConfirmModal />
      <DeleteTemplateModal />
      <SaveNewTemplateModal />
      <DeleteInsertedRowModal />
      <NewTemplateModal />
      <MaxTemplateModal />
      <DeleteWikiModal />
      <SaveInsertNewWikiModal />
      <UpdateWikiConfirmModal />
      <NewWikiSRRowModal />
      <NewWikiSRRowConfirmModal />
      <DeleteWikiSRRowConfirmModal />
      <EditWikiSRRowDetailsModal />
      <EditNewWikiSRRowConfirmModal />
      <LogEnquiryConfirmModal
        selectedCallToWrap={selectedCallToWrap}
        setSelectedCallToWrap={setSelectedCallToWrap}
      />
      <EditLogEnquiryModal />
      <EditChatReasonConfirmModal />
      <SelectEngineerWrapModal
        selectedCallToWrap={selectedCallToWrap}
        setSelectedCallToWrap={setSelectedCallToWrap}
      />
      <DeleteChatReasonConfirmModal />
      <NewChatReasonConfirmModal />
      <AgentUserNameErrorModal />
    </div>
  );
};

export default Main;
