import React from 'react';

const EditWikiModal = () => (
  <div id="editWikiModal" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog editDialog">
      <div className="modal-content editContentModal">
        <div style={{ padding: 10 }}>
          <div className="modal-header editpopupHeader" style={{ padding: 5, display: 'block' }}>
            <h5 id="editwikiTitle" className="col-12 modal-title" style={{ textAlign: 'left' }}> </h5>
            <div id="editwikiError" className="col-sm-12" style={{ display: 'none' }}>
              Please ensure all required fields are filled out.
              Where no information is available, insert NA
            </div>
          </div>
          <div className="modal-body editWikiPopupBody">
            <div id="editWikiPanel">
              <div className="row">
                <span id="editWikiRooId" style={{ display: 'none' }} />
              </div>
              <div className="row editWikiRow">
                <div className="col-sm-4"><span className="addWikiHeaders">Parent/Problem/INC</span></div>
                <div className="col-sm-8">
                  <textarea id="editWikiParentInc" className="editWikiTextarea" placeholder="Required" rows={1} cols={50} defaultValue="" />
                  <div id="beforeEditWikiParentInc" hidden />
                </div>
              </div>
              <div className="row editWikiRow">
                <div className="col-sm-4"><span className="addWikiHeaders">Issue Header</span></div>
                <div className="col-sm-8">
                  <textarea id="editWikiIssueHeader" className="editWikiTextarea" placeholder="Required" rows={1} cols={50} defaultValue="" />
                  <div id="beforeeditWikiIssueHeader" hidden />
                </div>
              </div>
              <div className="row editWikiRow">
                <div className="col-sm-4"><span className="addWikiHeaders">Error Message</span></div>
                <div className="col-sm-8">
                  <textarea id="editWikiSRError" className="editWikiTextarea" placeholder="Required" rows={1} cols={50} defaultValue="" />
                  <div id="beforeeditWikiSRError" hidden />
                </div>
              </div>
              <div className="row editWikiRow">
                <div className="col-sm-4"><span className="addWikiHeaders">Issue/Root cause</span></div>
                <div className="col-sm-8">
                  <textarea id="editWikiRootCause" className="editWikiTextarea" placeholder="Required" rows={3} cols={50} defaultValue="" />
                  <div id="beforeeditWikiRootCause" hidden />
                </div>
              </div>
              <div className="row editWikiRow">
                <div className="col-sm-4"><span className="addWikiHeaders">How to identify/symptoms</span></div>
                <div className="col-sm-8">
                  <textarea id="editWikiSymptoms" className="editWikiTextarea" placeholder="Required" rows={3} cols={50} defaultValue="" />
                  <div id="beforeeditWikiSymptoms" hidden />
                </div>
              </div>
              <div className="row editWikiRow">
                <div className="col-sm-4"><span className="addWikiHeaders">Workaround</span></div>
                <div className="col-sm-8">
                  <textarea id="editWikiWorkaround" className="editWikiTextarea" placeholder="Required" rows={3} cols={50} defaultValue="" />
                  <div id="beforeeditWikiWorkaround" hidden />
                </div>
              </div>
              <div className="row editWikiRow">
                <div className="col-sm-4"><span className="addWikiHeaders">Asset</span></div>
                <div className="col-sm-8">
                  <textarea id="editWikiAsset" className="editWikiTextarea" placeholder="Required" rows={1} cols={50} defaultValue="" />
                  <div id="beforeeditWikiAsset" hidden />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer editpopupFooter">
            <button type="button" className="btn cancelSection" onClick={() => (window as any).cancelEditWiki()}>Cancel</button>
            <button type="button" id="updateWikiDetails" className="btn DoneBtn" onClick={() => (window as any).updateWikisubmit()}>Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EditWikiModal;
