import React from 'react';

const EditLogEnquiryModal = () => (
  <div id="editEnquiryFormModal" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog editDialog">
      <div className="modal-content editContentModal">
        <div style={{ padding: 10 }}>
          <div className="modal-header editpopupHeader">
            <h5 id="editLogEnquiryTitle" className="col-12 modal-title popupTitle">Log Enquiry Contact</h5>
            <br />
            <span id="editLogEnquiryErrors" style={{ display: 'none' }} />
          </div>
          <div className="modal-body">
            {/* First part start */}
            <div id="editWikiPanel">
              <div className="row">
                <span id="editWikiRooId" style={{ display: 'none' }} />
              </div>
              <div className="row editWikiRow">
                <div className="col-sm-3">
                  <span className="addWikiHeaders">Chat reason: level 1</span>
                </div>
                <div className="col-sm-9">
                  <select aria-label="Select Reason" className="editSelectchatReasonLevel" id="chatReasonLevel1" />
                  <div id="beforeEditWikiParentInc" hidden />
                  <span className="resetButton">
                    <i className="fas fa-redo-alt" onClick={() => (window as any).resetChatReasonData()} aria-hidden="true" />
                  </span>
                </div>
              </div>
            </div>
            <div className="row editWikiRow">
              <div className="col-sm-3">
                <span className="addWikiHeaders">Chat reason: level 2</span>
              </div>
              <div className="col-sm-9">
                <select aria-label="Select Reason" className="editSelectchatReasonLevel" id="chatReasonLevel2" />
                <div id="beforeeditWikiIssueHeader" hidden />
              </div>
            </div>
            {/* First part end */}
            <hr />
            {/* second part start */}
            <div className="modal-body editPopupBody" style={{ padding: '0px 10px' }}>
              <div id="editReasonLevel" />
            </div>
            {/* second part end */}
          </div>
          <div className="modal-footer editpopupFooter">
            <button type="button" id="addNewChatReasonRow" className="btn" onClick={() => (window as any).addNewChatReason()}>Add</button>
            <button type="button" id="editEnquiryFormDoneBtn" className="btn DoneBtn" onClick={() => (window as any).editEnquiryFormDoneModal()}>Done</button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EditLogEnquiryModal;
