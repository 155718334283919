import React from 'react';

const EditTemplateModal = () => (
  <div id="editModal" className="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog editDialog">
      <div className="modal-content editContentModal">
        <div style={{ padding: 10 }}>
          <div className="modal-header editpopupHeader">
            <h5 id="editTemplateTitle" className="col-12 modal-title popupTitle"> </h5>
            <br />
            <span id="editErrors" style={{ display: 'none' }} />
          </div>
          <div className="modal-body editPopupBody" style={{ padding: '0px 10px' }}>
            <div id="editTemplate" />
          </div>
          <div className="modal-footer editpopupFooter">
            <button type="button" id="addTemplateSection" className="btn" onClick={() => (window as any).addNewTemplate()}>Add</button>
            <button type="button" id="editDoneBtn" className="btn DoneBtn" onClick={() => (window as any).cancelEditTemplate()}>Done</button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EditTemplateModal;
